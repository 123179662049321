<template>
  <div v-if="selectIpDn && Object.keys(selectIpDn).length" class="process_group_list_preview_ipdn">
    <!-- エラー表示 -->
    <div class="errors" v-if="isErrors">
      <div class="tw_process_group_list_item">
        <div class="value">{{selectIpDn.errors.message}}</div>
      </div>
    </div>
    <!-- エラー詳細 -->
    <div class="errors meta" v-if="isErrors && errMeta && errMeta.length">
      <div v-for="(array, index) in errMeta" :key="index" class="tw_process_group_list_item">
        <div v-for="(item, i) in array" :key="i" class="value">{{item}}</div>
      </div>
    </div>
    <div>
      <template v-for="(group, index) in ipGroups">
        <TwProcessGroupListPreviewIpDnItem
          :key="`ip_${index}`"
          :group="group"
          :tableName="ipTable.variableName"
          :isEditMode="isEditMode"
          poolName="ippools"
          :selectIndex="selectIpIndex"
          :initOpen="true"
        />
      </template>
    </div>
    <div v-if="docType === 'DN'">
      <template v-for="(group, index) in dnGroups">
        <TwProcessGroupListPreviewIpDnItem
          :key="`dn_${index}`"
          :group="group"
          :tableName="dnTable.variableName"
          :isEditMode="isEditMode"
          poolName="dnpools"
          :selectIndex="selectDnIndex"
          :initOpen="true"
        />
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import schemas from '@/dictionaries/processes/miip.json';
import TwProcessGroupListPreviewIpDnItem from '@/components/molecules/TwProcessGroupListPreviewIpDnItem';
import { IP_REGIST_TYPE } from 'lib-tw-common';

export default {
  name: 'TwProcessGroupListPreviewIpDn',
  components: {
    TwProcessGroupListPreviewIpDnItem
  },
  props: {
    selectIndex: Number,
    isEditMode: Boolean,
    docType: String,
    table: Array
  },
  inject: ['s'],
  data() {
    return {
      open: true,
      ipTable: _.find(schemas.tables, { variableName: 'linkageMarineInsurance' }) || [],
      dnTable: _.find(schemas.tables, { variableName: 'marineInsuranceSeparate' }) || []
    };
  },
  computed: {
    selectIpIndex() {
      const table = this.isEditMode ? this.s.cloneItems.linkageMarineInsurance : this.s.res.linkageMarineInsurance;
      if (table && table.ippools && table.ippools.length) {
        if (this.docType === 'DN') {
          const index = _.findIndex(table.ippools, ip => {
            // DNから追加したIP情報を検索
            const ipPolicy = ip.policyGrp;
            const dnPolicy = this.selectIpDn.policyGrp;
            // DNから追加したIPかどうか + IPとDNのキー情報が一致しているかどうか
            return (
              ip.ipRegistType === IP_REGIST_TYPE.DN &&
              ipPolicy.ipDnTypeDn === dnPolicy.ipDnType &&
              ipPolicy.senderCompanyId === dnPolicy.senderCompanyId &&
              ipPolicy.insurancePolicyNo === dnPolicy.insurancePolicyNo &&
              ipPolicy.insurancePolicyBranchNo === dnPolicy.insurancePolicyBranchNo &&
              ipPolicy.trx === dnPolicy.trx
            );
          });
          return index !== -1 ? index : null;
        } else {
          // 選択中のipItemsと同一のキー情報をもつI/PのIndexを返却
          // PB-643の対応でキー項目が同一のIP情報は集約表示しているため
          // https://tradewaltz.backlog.com/view/PB-643
          const ipItems = _.get(this, 's.ipItems') || _.get(this, 's.ipPreviewItems') || [];
          const ipItem = ipItems[this.selectIndex];
          if (ipItem) {
            const index = _.findIndex(table.ippools, ip => {
              const ipPolicy = ip.policyGrp;
              return (
                ipItem.ipRegistType === ip.ipRegistType &&
                ipItem.ipDnType === ipPolicy.ipDnType &&
                ipItem.ipDnTypeDn === ipPolicy.ipDnTypeDn &&
                ipItem.senderCompanyId === ipPolicy.senderCompanyId &&
                ipItem.insurancePolicyNo === ipPolicy.insurancePolicyNo &&
                ipItem.insurancePolicyBranchNo === ipPolicy.insurancePolicyBranchNo
              );
            });
            return index !== -1 ? index : this.selectIndex || null;
          } else {
            return this.selectIndex;
          }
        }
      } else {
        return null;
      }
    },
    selectDnIndex() {
      return this.selectIndex;
    },
    selectIpDn() {
      let table;
      if (this.docType === 'DN') {
        table = this.isEditMode ? this.s.cloneItems.marineInsuranceSeparate : this.s.res.marineInsuranceSeparate;
        if (table.dnpools && table.dnpools.length) {
          return table.dnpools[this.selectIndex] || {};
        } else return {};
      } else {
        table = this.isEditMode ? this.s.cloneItems.linkageMarineInsurance : this.s.res.linkageMarineInsurance;
        if (table.ippools && table.ippools.length) {
          return table.ippools[this.selectIpIndex] || table.ippools[this.selectIndex] || {};
        } else return {};
      }
    },
    ipGroups() {
      const ipTable = this.s.cloneDeep(this.ipTable);
      const groupName = ['keyGrp', 'insuranceTermsGrp', 'insurancePolicyGrp', 'forwarderIpGrp', 'remarksGrp'];
      return _.filter(ipTable.groups, group => {
        return !groupName.includes(group.variableName);
      });
    },
    dnGroups() {
      const dnTable = this.s.cloneDeep(this.dnTable);
      const groupName = ['keyGrp', 'remarksGrp'];
      return _.filter(dnTable.groups, group => {
        return !groupName.includes(group.variableName);
      });
    },
    isErrors() {
      const errors = _.get(this.selectIpDn, 'errors', {});
      return errors && Object.keys(errors).length > 0;
    },
    errMeta() {
      return _.get(this.selectIpDn, 'errors.meta', []);
    }
  },
  created() {
    this.$parent.$on('openAll', this.openAll);
    this.$parent.$on('closeAll', this.closeAll);
  },
  beforeDestroy() {
    this.$parent.$off('openAll', this.openAll);
    this.$parent.$off('closeAll', this.closeAll);
  },
  methods: {
    openAll() {
      this.open = true;
      this.$emit('openAll');
    },
    closeAll() {
      this.open = false;
      this.$emit('closeAll');
    }
  }
};
</script>

<style lang="scss" scoped>
.process_group_list_preview_ipdn {
  display: flex;
  flex-wrap: wrap;
  // NOTE: 「\n」改行対応
  white-space: pre-wrap;
}
.tw_process_group_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // border-bottom: 1px solid $color_gray_300;
  margin-bottom: 8px;
  &:first-child {
    margin-top: 8px;
    // border: 0;
  }
  &:last-child {
    border-bottom: none;
  }
  &.goods {
    .tw_process_group_list_item {
      padding-bottom: 8px;
      &:nth-child(6n-1),
      &:nth-child(6n) {
        padding-bottom: 32px;
      }
    }
  }
}
.tw_process_group_list_item {
  width: 50%;
  min-width: 300px;
  display: flex;
  padding: 0 0 16px 16px;
  &.full {
    width: 100%;
  }
  .label {
    width: 118px;
    font-size: 12px;
    line-height: 18px;
    color: #9191a4;
    margin-right: 8px;
    vertical-align: top;
    padding-top: 2px;
  }
  .value {
    font-size: 14px;
    line-height: 20px;
    color: #3e3a39;
    vertical-align: top;
    flex: 1;
  }
}
.errors {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .tw_process_group_list_item {
    width: 100%;
    margin-bottom: 16px;
    padding: 0;
    .value {
      color: $color_warning;
    }
    &:first-child {
      margin-top: 24px;
    }
    &:last-child {
      margin-bottom: 24px;
    }
  }
  &.meta {
    .tw_process_group_list_item {
      margin: 8px 0 4px;
      &:first-child {
        margin-top: 8px;
      }
      &:last-child {
        margin-bottom: 24px;
      }
      .value {
        margin-right: 16px;
      }
    }
  }
}
</style>
